<template lang="pug">
AuthPageLayout(title="Войдите для начала работы")
  v-text-field(
    label="Логин"
    filled
    v-model="username"
    @input="authError = null"
    :error-messages="getValidationErrors('username')"
    :error="!!authError"
    :disabled="$root.isDisabledGlobal"
  )
  v-text-field(
    label="Пароль"
    type="password"
    filled
    v-model="password"
    @input="authError = null"
    :error-messages="authError || getValidationErrors('password')"
    :disabled="$root.isDisabledGlobal"
  )

  template(#actions)
    v-btn(
      @click="onSubmit"
      :loading="$root.isLoadingGlobal"
      height="40"
      color="primary"
    ) Войти
    div.mt-2
      v-btn(
        v-for="[username, password] in testCredentials"
        :key="username"
        :disabled="$root.isLoadingGlobal"
        depressed
        x-small
        @click="signInBy(username, password)"
      ) Log in by {{ username }}
  template(#modals)
    TheMNV
</template>

<script>
import AuthPageLayout from '@/components/layouts/AuthPageLayout';
import TheMNV from '@/components/TheMNV';
import mixins from '@/utils/mixins';
import Validate from '@/validations/signin';
import { mapActions, mapGetters } from 'vuex';

export default mixins(Validate).extend({
  components: {
    AuthPageLayout,
    TheMNV,
  },

  data() {
    return {
      username: '',
      password: '',
      authError: null,
    };
  },

  computed: mapGetters('AUTH', ['testCredentials']),

  // created() {
  //   document.addEventListener('keyup', this.onEnter);
  // },

  // destroyed() {
  //   document.removeEventListener('keyup', this.onEnter);
  // },

  methods: {
    ...mapActions('AUTH', ['signIn']),
    onEnter(e) {
      e.keyCode === 13 && this.onSubmit();
    },
    reset() {
      this.username = '';
      this.password = '';
    },
    // For comfortable development
    signInBy(username = 'superuser', password = 'admin') {
      this.username = username;
      this.password = password;
      this.onSubmit();
    },

    async onSubmit() {
      this.authError = null;
      this.$v.$touch();
      this.setServerValidationErrors([]);

      if (this.$v.$invalid) {
        return null;
      }

      try {
        await this.signIn({ username: this.username, password: this.password });
        this.$router.push({ name: 'main' });
      } catch (error) {
        if (error.response?.status === 400) {
          this.setServerValidationErrors(error.response.data?.inner);
        } else {
          this.authError = error.message;
        }
      }
    },
  },
});
</script>
