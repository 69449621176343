<template>
  <div v-if="isShow" class="mnv__container">
    <div class="mnv__modal">
      <div class="mnv__content">
        <h2 class="mnv__title">Важная информация!</h2>
        <p>
          ВНИМАНИЕ! Medpoint24 обновляет систему на новую версию, в которой появились дополнительные полезные функции и возможности, подробнее о них можно прочитать
          <strong>
            <a href="https://v3.distmed.com/docs/about_v3" target="_blank">здесь</a>
          </strong>.
        </p>
        <p>
          Если Вы не видите осмотры в текущем кабинете, переходите в новый АРМ
          <strong>
            <a href="https://v3.distmed.com/">v3.distmed.com</a>
          </strong>.
        </p>
        <p>
          Учетная запись и доступ в обновленный АРМ создаются только по e-mail, если вы не получали письмо с логином и паролем от
          <strong>postmaster@medpoint24.ru</strong>, пожалуйста обратитесь в техническую поддержку или к своему менеджеру.
        </p>

        <div class="mnv__actions">
          <button class="mnv__close-button" @click="isShow = false">Закрыть</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    isShow: true,
  }),
};
</script>

<style>
.mnv__container {
  background-color: rgba(0, 0, 0, 0.55);

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;

  display: flex;
  justify-content: center;
  align-items: center;
}

.mnv__modal {
  background-color: white;
  border-radius: 25px;

  width: 550px;
}

.mnv__content {
  margin: 40px;
}

.mnv__actions {
  display: flex;
  justify-content: flex-end;
}

.mnv__close-button {
  background-color: #1976d2;
  border-color: #1976d2;
  color: #fff;

  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  flex: 0 0 auto;
  font-weight: 500;
  justify-content: center;
  outline: 0;
  text-decoration: none;
  text-transform: uppercase;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),
    0 1px 5px 0 rgb(0 0 0 / 12%);
  padding: 0 20px;
  height: 40px;
}
</style>
