<template lang="pug">
  v-layout(fill-height justify-center align-center column)
    slot(name="modals")
    div(style="width:490px;max-width:100vw;")
      v-img.ma-auto.mb-9(
        src="@/assets/images/logo-blue.svg"
        max-height="54"
        max-width="214"
        contain
      )
      v-card
        v-card-title.mb-6.justify-center {{title}}
        v-card-text.pb-0
          slot
        v-card-actions.d-flex.justify-center.flex-column
          slot(name="actions")
    div.mt-9.text-center.text--disabled {{copyright}}
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    copyright: {
      type: String,
      default: '2017 – ' + new Date().getFullYear() + ' г. © DistMed.com',
    },
  },
};
</script>
´
